/** @format */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedFormId } from 'state/slice/buttonSlice';
import { getAcaRequestForms } from 'api/formsRoute';
import ExportCSVButton from './super-admin/widgets/exportButton';

const { Column } = Table;

const ACARequestFormsTable = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAcaRequestForms(startDate, endDate);
        // If response is an array, use it; otherwise, default to an empty array.
        const forms = Array.isArray(response) ? response : [];
        setData(forms);
      } catch (error) {
        console.error('Error fetching data:', error);
        // On error, default to an empty array.
        setData([]);
      }
    };
    fetchData();
  }, [startDate, endDate]);
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleFormView = (formId) => {
    dispatch(setSelectedFormId(formId)); // Dispatch the action to set selected form
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Pending':
        return { backgroundColor: 'yellow', color: 'black', padding: '5px' };
      case 'Paid':
        return { backgroundColor: 'green', color: 'white', padding: '5px' };
      case 'Canceled':
        return { backgroundColor: 'red', color: 'white', padding: '5px' };
      case 'Declined':
        return { backgroundColor: 'grey', color: 'white', padding: '5px' };
      default:
        return {};
    }
  };
const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    ...getColumnSearchProps('firstName'),
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    ...getColumnSearchProps('lastName'),
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    ...getColumnSearchProps('phoneNumber'),
  },
  {
    title:'state',
    dataIndex: ['address', 'state'],
    key: 'state',
  },
  {
    title: 'Agent Name',
    dataIndex: 'agentName',
    key: 'agentName',
    ...getColumnSearchProps('agentName'),
  },
  {
    title: 'Carrier',
    dataIndex: ['majorMed', 'carrier'],
    key: 'carrier',
    ...getColumnSearchProps('majorMed.carrier'),
  },
  {
    title: 'Vendor Name',
    dataIndex: 'vendorName',
    key: 'vendorName',
    ...getColumnSearchProps('vendorName'),
  },
  {
    title: 'Billing Date',
    dataIndex: ['majorMed', 'billingDate'],
    key: 'billingDate',
    render: (text) =>
      text
        ? new Date(text).toLocaleDateString('en-US', { timeZone: 'UTC' })
        : 'N/A',
  },
  
  {
    title: 'Status',
    dataIndex: ['majorMed', 'status'],
    key: 'status',
    render: (text) => {
      console.log('Status Text:', text); // Check the value of `text`

      const displayText = text;
      return <span style={getStatusStyle(displayText)}>{displayText}</span>;
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Button type='primary' onClick={() => handleFormView(record._id)}>
        View
      </Button>
    ),
  },
];

 
  return (
    <div style={{ width: '100%' }}>
      {/* Add this line to include the export button */}
      <Table
        dataSource={data}
        rowKey='_id'
        bordered
        style={{ width: '100%' }}
        scroll={{ x: 'max-content',  }} // Add this line to make the table scrollable
      >
        {columns.map((column) => (
          <Column {...column} />
        ))}
      </Table>
    </div>
  );
};

export default ACARequestFormsTable;
