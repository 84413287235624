/** @format */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getRankClass } from 'utils/getRankClass';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const AgentStatsTable = ({ endpoint }) => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App

  useEffect(() => {
    const fetchSalesData = async () => {
      if (!startDate || !endDate) return;

      const token = localStorage.getItem('accessToken');

      try {
        const response = await axios.get(`${apiUrl}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { startDate, endDate },
        });

        // Aggregate data by userId
        const aggregatedData = response.data.reduce((acc, curr) => {
          const userId = curr.userId;
          if (!acc[userId]) {
            acc[userId] = { ...curr };
          } else {
            acc[userId].totalPrivateMedSales += curr.totalPrivateMedSales || 0;
            acc[userId].totalPrivateMedPremium += curr.totalPrivateMedPremium || 0;
            acc[userId].totalMajorMedSales += curr.totalMajorMedSales || 0;
            acc[userId].totalMajorMedPremium += curr.totalMajorMedPremium || 0;
            acc[userId].totalAncillariesSales += curr.totalAncillariesSales || 0;
            acc[userId].totalAncillariesPremium += curr.totalAncillariesPremium || 0;
            acc[userId].totalLeads += curr.totalLeads || 0;
            acc[userId].totalBillableLeads += curr.totalBillableLeads || 0;
            acc[userId].totalCost += curr.totalCost || 0;
          }
          return acc;
        }, {});

        // Convert the aggregated object back into an array
        const combinedData = Object.values(aggregatedData);
        setSalesData(combinedData);
      } catch (error) {
        setError('Error fetching salesTable');
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [startDate, endDate, apiUrl, endpoint]);

  // Helper functions for computed values
  const rate = (total, fraction) => {
    if (!total) return 0;
    return Math.round((fraction / total) * 100);
  };

  const cpa = (totalCost, totalSales) => {
    if (!totalSales) return 0;
    return Math.round(totalCost / totalSales);
  };

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getCommission = (totalSales) => {
    const commission = totalSales * 0.4;
    return commission.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      render: (_, __, index) => (
        <span className={`table-rank ${getRankClass(index)}`}>{index + 1}</span>
      ),
    },
    {
      title: 'User',
      dataIndex: 'agentName',
      key: 'agentName',
      render: (text) => capitalizeFullName(text),
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
    },
    {
      title: 'Bill Rate',
      dataIndex: 'totalLeads',
      key: 'totalLeads',
      render: (text, record) =>
        `${rate(record.totalLeads, record.totalBillableLeads)}%`,
      sorter: (a, b) =>
        rate(a.totalLeads, a.totalBillableLeads) - rate(b.totalLeads, b.totalBillableLeads),
    },
    {
      title: 'CPA-A',
      dataIndex: 'totalPrivateMedSales',
      key: 'cpaA',
      render: (text, record) => (
        <span
          style={{
            color: cpa(record.totalCost, record.totalPrivateMedSales) < 300 ? '#A1E3CB' : '#FF000094',
          }}
        >
          ${cpa(record.totalCost, record.totalPrivateMedSales)}
        </span>
      ),
      sorter: (a, b) => cpa(a.totalCost, a.totalPrivateMedSales) - cpa(b.totalCost, b.totalPrivateMedSales),
    },
    {
      title: 'CPA-B',
      dataIndex: 'totalMajorMedSales',
      key: 'cpaB',
      render: (text, record) => (
        <span
          style={{
            color:
              cpa(record.totalCost, record.totalPrivateMedSales + record.totalMajorMedSales) < 100
                ? '#A1E3CB'
                : '#FF000094',
          }}
        >
          ${cpa(record.totalCost, record.totalPrivateMedSales + record.totalMajorMedSales)}
        </span>
      ),
      sorter: (a, b) =>
        cpa(a.totalCost, a.totalPrivateMedSales + a.totalMajorMedSales) -
        cpa(b.totalCost, b.totalPrivateMedSales + b.totalMajorMedSales),
    },
    {
      title: 'Spend',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (text) =>
        `$${text.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      sorter: (a, b) => a.totalCost - b.totalCost,
    },
    {
      title: 'ACA',
      dataIndex: 'totalMajorMedSales',
      key: 'totalMajorMedSales',
      sorter: (a, b) => a.totalMajorMedSales - b.totalMajorMedSales,
    },
    {
      title: 'U65',
      dataIndex: 'totalPrivateMedSales',
      key: 'totalPrivateMedSales',
      sorter: (a, b) => a.totalPrivateMedSales - b.totalPrivateMedSales,
    },
    {
      title: 'Q Calls',
      dataIndex: 'totalBillableLeads',
      key: 'totalBillableLeads',
      sorter: (a, b) => a.totalBillableLeads - b.totalBillableLeads,
    },
    {
      title: 'Total Calls',
      dataIndex: 'totalLeads',
      key: 'totalLeads',
      sorter: (a, b) => a.totalLeads - b.totalLeads,
    },
    {
      title: 'Premium',
      dataIndex: 'totalPremium',
      key: 'totalPremium',
      render: (text, record) => (
        <span>
          ${(
            record.totalAncillariesPremium + record.totalPrivateMedPremium
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
      sorter: (a, b) =>
        (a.totalAncillariesPremium + a.totalPrivateMedPremium) -
        (b.totalAncillariesPremium + b.totalPrivateMedPremium),
      defaultSortOrder: 'descend',
    },
    {
      title: 'CR',
      dataIndex: 'totalPrivateMedSales',
      key: 'conversionRate',
      render: (text, record) => {
        const conversion = rate(
          record.totalBillableLeads,
          record.totalPrivateMedSales + record.totalMajorMedSales
        );
        return (
          <span style={{ color: conversion > 60 ? '#A1E3CB' : '#FF000094' }}>
            {conversion}%
          </span>
        );
      },
      sorter: (a, b) =>
        rate(a.totalBillableLeads, a.totalPrivateMedSales + a.totalMajorMedSales) -
        rate(b.totalBillableLeads, b.totalPrivateMedSales + b.totalMajorMedSales),
    },
    {
      title: 'EC',
      dataIndex: 'EC',
      key: 'EC',
      render: (text, record) => (
        <span>
          ${getCommission(record.totalPrivateMedPremium + record.totalAncillariesPremium)}
        </span>
      ),
      sorter: (a, b) =>
        (a.totalPrivateMedPremium + a.totalAncillariesPremium) -
        (b.totalPrivateMedPremium + b.totalAncillariesPremium),
    },
  ];

  // Create a computed version of salesData that includes computed fields
  const computedSalesData = salesData.map(item => ({
    ...item,
    billRate: `${rate(item.totalLeads, item.totalBillableLeads)}%`,
    cpaA: `$${cpa(item.totalCost, item.totalPrivateMedSales)}`,
    cpaB: `$${cpa(item.totalCost, item.totalPrivateMedSales + item.totalMajorMedSales)}`,
    conversionRate: `${rate(item.totalBillableLeads, item.totalPrivateMedSales + item.totalMajorMedSales)}%`,
    ec: `$${getCommission(item.totalPrivateMedPremium + item.totalAncillariesPremium)}`,
  }));

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: '16px', float: 'right' }}>
        <CSVLink
          data={computedSalesData}
          filename="agent-stats.csv"
          
        >
         <Button icon={<DownloadOutlined />}>Export CSV</Button>
        </CSVLink>
      </div>
      <Table
        columns={columns}
        dataSource={salesData}
        rowKey={(record) => record.userId}
        loading={loading}
        pagination={false}
        bordered
      />
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default AgentStatsTable;
