/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Col, Row } from 'antd';
import Downtrend from 'img/ArrowFall.svg';
import Uptrend from 'img/ArrowRise.svg';
import Info from 'img/Info.svg';
import MonthlyLeadsAndSalesChart from 'components/widgets/monthlyLeadsAndSalesBar';
//import TopCampaignTable from "components/widgets/topcampaign";
import DashboardSkeleton from 'components/admin/skeleton/dashboardSkeleton';
import { Tooltip } from 'antd';
import PremiumsChart from 'components/super-admin/widgets/lineChart';
const AgentStats = () => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const [includePremium, setIncludePremium] = useState(false);
  // Check if userData and userData.division are defined and is an array
const { officeId} = useSelector((state) => state.user.userData);
console.log("officeId",officeId);
  useEffect(() => {
    const fetchSummary = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        const response = await axios.get(
          `${apiUrl}/api/stats/agent/sales-summary`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: startDate,
              endDate: endDate,
            },
          }
        );
        console.log('API Response:', response.data);
        setSummary(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching summary data:', err);
        setError(
          err.response ? err.response.data.error : 'Error fetching summary data'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [startDate, endDate, apiUrl]);

  if (loading) {
    return <DashboardSkeleton />;
  }

  if (error) {
    console.log('Error State:', error);
    return <div>Error: {error}</div>;
  }

  // Function to calculate percentage rate
  const rate = (total, fraction) => {
    if (total === 0) return 0;
    if (!total || !fraction) return 0;
    const percentage = (fraction / total) * 100;
    return Math.round(percentage);
  };

  // Function to format amount as local string
  const localString = (amount) => {
    return amount ? amount.toLocaleString() : '0';
  };

  // Function to calculate CPA
  const cpa = (totalCost, totalSales) => {
    if (!totalCost || !totalSales) return 0;
    const average = totalCost / totalSales;
    return Math.round(average);
  };

  // Function to calculate percentage rate trend
  const rateTrend = (previous, current) => {
    if (previous === 0) return current === 0 ? 0 : 100;
    if (!previous || !current) return 0;
    return ((current - previous) / previous) * 100;
  };

  const totalSpentTrend = rateTrend(summary.previousCost, summary.totalCost);

  const cpaTrend = rateTrend(
    cpa(summary.previousCost, summary.previousU65Sales),
    cpa(summary.totalCost, summary.totalU65Sales)
  );
  const leadsTrend = rateTrend(
    summary.previousBillableLeads,
    summary.totalBillableLeads
  );
  const totalSalesTrend = rateTrend(
    summary.previousACASales + summary.previousU65Sales,
    summary.totalU65Sales + summary.totalACASales
  );
  const maJorMedTrend = rateTrend(
    summary.previousACASales,
    summary.totalACASales
  );
  const acaPremium = summary.totalACASales * 50;
  const totalPremium =
    summary.totalPrivateMedPremium + summary.totalAncillariesPremium;

  const privateMedTrend = rateTrend(
    summary.previousU65Sales,
    summary.totalU65Sales
  );
  const ancillariesTrend = rateTrend(
    summary.previousAncillaries,
    summary.totalAncillaries
  );
  const totalCost = summary.totalCost;
  return (
    <div className='dashboard-container'>
      <Row gutter={[28, 28]} className='rates'>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-blue'>
            <div className='stats-title'>
              <span> Estimated Total Spend</span>
              <Tooltip title='Total spent for the week'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <span>Not available for you</span>
              <div>
                <span>
                  {totalSpentTrend.toFixed(2)}%
                  <img
                    src={totalSpentTrend < 0 ? Downtrend : Uptrend}
                    alt={totalSpentTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span> P.T.C</span>
              <Tooltip title='Total Premium to Total Marketing cost'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>
                {(() => {
                  const premium = parseFloat(totalPremium);

                  const cost = parseFloat(totalCost);
                  console.log(cost);
                  if (cost === 0) return '0.00x';
                  return (premium / cost).toFixed(2) + 'x';
                })()}
              </h2>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>CPA-A</span>
              <Tooltip title='Cost Per Acquisition this week '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>
                {' '}
                ${localString(cpa(summary.totalCost, summary.totalU65Sales))}
              </h2>
              <div>
                <span>
                  {cpaTrend.toFixed(2)}%
                  <img
                    src={cpaTrend < 0 ? Downtrend : Uptrend}
                    alt={cpaTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-purple' style={{ height: '100%' }}>
            <div className='stats-title'>
              <span>Leads</span>
              <Tooltip title='Total leads this week'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <span>Not available for you</span>
              <div>
                <span>
                  {leadsTrend.toFixed(2)}%
                  <img
                    src={leadsTrend < 0 ? Downtrend : Uptrend}
                    alt={leadsTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>CPA-B</span>
              <Tooltip title='Cost Per Acquisition this week '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <span>Not available for you</span>
              <div>
                <span>
                  {cpaTrend.toFixed(2)}%
                  <img
                    src={cpaTrend < 0 ? Downtrend : Uptrend}
                    alt={cpaTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>Total Premium</span>
              <Tooltip title='Cost Per Acquisition this week '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>
                {' '}
                {localString(
                  summary.totalPrivateMedPremium +
                    summary.totalAncillariesPremium
                )}
              </h2>
              <div>
                <span>
                  {maJorMedTrend.toFixed(2)}%
                  <img
                    src={maJorMedTrend < 0 ? Downtrend : Uptrend}
                    alt={maJorMedTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>ACA Core</span>
              <Tooltip title='Cost Per Acquisition this week '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2> {summary.totalACASales}</h2>
              <div>
                <span>
                  {maJorMedTrend.toFixed(2)}%
                  <img
                    src={maJorMedTrend < 0 ? Downtrend : Uptrend}
                    alt={maJorMedTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div
            className='rates-purple'
            style={{ backgroundColor: '#baedbd', height: '100%' }}>
            <div className='stats-title'>
              <span>Close Ratio</span>
              <Tooltip title='Close Ratio This Week'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='states'>
              <h2>
                {' '}
                {rate(
                  summary.totalBillableLeads,
                  summary.totalACASales + summary.totalU65Sales
                )}
                %
              </h2>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>U65 Core</span>
              <Tooltip title='Cost Per Acquisition this week '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2> {summary.totalU65Sales}</h2>
              <div>
                <span>
                  {privateMedTrend.toFixed(2)}%
                  <img
                    src={privateMedTrend < 0 ? Downtrend : Uptrend}
                    alt={privateMedTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span> Ancillaries</span>
              <Tooltip title='Cost Per Acquisition this week '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2> {summary.totalAncillaries}</h2>
              <div>
                <span>
                  {ancillariesTrend.toFixed(2)}%
                  <img
                    src={ancillariesTrend < 0 ? Downtrend : Uptrend}
                    alt={ancillariesTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>Total Post Dates</span>
              <Tooltip title='Money Spent Today'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>{localString(summary.totalPost)}</h2>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div
            className='rates-purple'
            style={{ backgroundColor: '#ffe999', height: '100%' }}>
            <div className='stats-title'>
              <span>Sales</span>
              <Tooltip title=' Total sales this week'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>{summary.totalACASales + summary.totalU65Sales}</h2>
              <div>
                <span>
                  {totalSalesTrend.toFixed(2)}%
                  <img
                    src={totalSalesTrend < 0 ? Downtrend : Uptrend}
                    alt={totalSalesTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <MonthlyLeadsAndSalesChart
        endpoint={`${apiUrl}/api/stats/agent/monthly-summary`}
      />
      <PremiumsChart endpoint={'api/stats/agent/weekly-premium'} />
      {/** <TopCampaignTable endpoint={`${apiUrl}/api/top-campaigns`} />*/}
    </div>
  );
};

export default AgentStats;
