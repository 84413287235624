import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Dropdown, Menu, message, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedFormId } from "state/slice/buttonSlice";
import axios from "axios";
import backArrow from "img/ArrowLeft.svg";
const { Column } = Table;

const RequestFormsTable = ({ path, setExportDatas }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Use the environment variable for the API URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/${path}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          params: {
            startTime: startDate,
            endTime: endDate,
          },
        });
        console.log("API response:", response.data); // Check the output!
        
        // If your API returns an object with the data array in a property (e.g., response.data.forms)
        // then use that property. Otherwise, if it returns an array directly, this is fine.
        setData(Array.isArray(response.data) ? response.data : response.data.forms || []);
        setExportDatas(Array.isArray(response.data) ? response.data : response.data.forms || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
  }, [startDate, endDate, apiUrl, path, setExportDatas]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : false,
    onFilterDropdownOpenChange: (open) => {
      if (open) {
        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.select();
          }
        }, 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleStatusChange = async (formId, status) => {
    try {
      await axios.put(
        `${apiUrl}/api/aca/request-form/update-form/${formId}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Status updated successfully");
      setData((prevData) =>
        prevData.map((item) =>
          item._id === formId ? { ...item, status } : item
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
      message.error("Failed to update status");
    }
  };

  const statusMenu = (formId, currentStatus) => (
    <Menu>
      {["Pending", "Paid", "Canceled", "Declined"].map((status) => (
        <Menu.Item
          key={status}
          onClick={() => handleStatusChange(formId, status)}>
          {status}
        </Menu.Item>
      ))}
    </Menu>
  );

  const getStatusStyle = (status) => {
    switch (status) {
      case "Pending":
        return { backgroundColor: "yellow", color: "black" };
      case "Paid":
        return { backgroundColor: "green", color: "white" };
      case "Canceled":
        return { backgroundColor: "red", color: "white" };
      case "Declined":
        return { backgroundColor: "grey", color: "white" };
      default:
        return {};
    }
  };

  const handleFormView = (formId) => {
    dispatch(setSelectedFormId(formId));
    // Additional logic for form view if needed
  };

  return (
    <div style={{ width: "100%" }}>
      <Table
        dataSource={data}
        rowKey="_id"
        bordered
        style={{ width: "100%" }}
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
      >
        <Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
          {...getColumnSearchProps("firstName")}
          width={150} // Set a fixed width
        />
        <Column
          title="Last Name"
          dataIndex="lastName"
          key="lastName"
          {...getColumnSearchProps("lastName")}
          width={150} // Set a fixed width
        />
        <Column
          title="Phone Number"
          dataIndex="phoneNumber"
          key="phoneNumber"
          {...getColumnSearchProps("phoneNumber")}
          />
          <Column
            title="State"
            dataIndex={['address', 'state']}
            key="state"
            render={(text) => <div style={{ width: 300 }}>{text}</div>}
            {...getColumnSearchProps("state")}
          />
          <Column
            title="Carrier"
            dataIndex="carrier"
            key="carrier"
            render={(text) => <div style={{width:"300px"}}>{text}</div>}
            {...getColumnSearchProps("carrier")}
        />
        <Column
          title="Billing Date"
          dataIndex="todaysDate"
          key="todaysDate"
          render={(text) =>
            new Date(text).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })
          }
          {...getColumnSearchProps("todaysDate")}
          width={150} // Set a fixed width
        />
        
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(text, record) => (
            <Dropdown
              overlay={statusMenu(record._id, text)}
              trigger={["click"]}>
              <Button style={getStatusStyle(text)}>{text}</Button>
            </Dropdown>
          )}
          width={150} // Set a fixed width
        />
        <Column
          title="Actions"
          key="actions"
          render={(text, record) => (
            <Button type="primary" onClick={() => handleFormView(record._id)}>
              View
            </Button>
          )}
          width={150} // Set a fixed width
        />
      </Table>
    </div>
  );
};

export default RequestFormsTable;
