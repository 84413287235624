/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { Table, Progress, Input, Button } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

const CampaignPerformance = ({ path }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // Default page size is 100
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessToken');
      try {
        const response = await axios.get(`${apiUrl}/api/${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { startDate, endDate },
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Initialize socket connection with the token
    const token = localStorage.getItem('accessToken');
    const socket = io(apiUrl, {
      query: { token }, // Send token with the connection
      transports: ['websocket'], // Make sure to use websocket
    });

    socket.on('connect', () => {
      console.log('Socket connected with ID: ', socket.id);
    });

    // Listen for campaign performance data from the socket
    socket.on('campaign-performance-data', (newData) => {
      setData(newData);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    return () => {
      // Clean up on component unmount
      socket.disconnect();
    };
  }, [startDate, endDate, path]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  // Sort campaign data (if needed)
  const sortedCampaignData = [...data].sort(
    (a, b) =>
      b.totalPrivateMedPremium +
      b.totalAncillariesPremium -
      (a.totalPrivateMedPremium + a.totalAncillariesPremium)
  );

  // Helper functions
  const rate = (total, fraction) => {
    if (total === 0) return 'No Data';
    const percentage = (fraction / total) * 100;
    return Math.round(percentage);
  };

  const cpa = (totalCost, totalSales) => {
    if (totalSales === 0) return 'No Data';
    const average = totalCost / totalSales;
    return Math.round(average);
  };

  // Filter data by vendor name based on the search term
  const filteredData = data.filter((item) =>
    item.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Create computed data for CSV export that includes the new "Bill Rate" field
  const computedData = data.map(item => ({
    ...item,
    billRate: item.totalLeads
      ? rate(item.totalLeads, item.totalBillableLeads) + "%"
      : "No Data"
  }));

  // Define columns for the table (with new "Bill Rate" column)
  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Vendor Name`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.vendorName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Bill Rate',
      key: 'billRate',
      render: (text, record) => (
        <span>
          {record.totalLeads
            ? `${rate(record.totalLeads, record.totalBillableLeads)}%`
            : 'No Data'}
        </span>
      ),
    },
    {
      title: 'CPA-A',
      dataIndex: 'cpaA',
      key: 'cpaA',
      sorter: (a, b) => {
        const cpaA = cpa(a.totalCost, a.totalU65Sales);
        const cpaB = cpa(b.totalCost, b.totalU65Sales);
        return cpaA - cpaB;
      },
      render: (text, record) => (
        <span
          style={{
            color:
              cpa(record.totalCost, record.totalU65Sales) < 300
                ? '#A1E3CB'
                : '#FF000094',
          }}>
          {cpa(record.totalCost, record.totalU65Sales) !== 'No Data'
            ? `$${cpa(record.totalCost, record.totalU65Sales)}`
            : 'No Data'}
        </span>
      ),
    },
    {
      title: 'CPA-B',
      dataIndex: 'cpaB',
      key: 'cpaB',
      sorter: (a, b) => {
        const cpaA = cpa(a.totalCost, a.totalU65Sales + a.totalACASales);
        const cpaB = cpa(b.totalCost, b.totalU65Sales + b.totalACASales);
        return cpaA - cpaB;
      },
      render: (text, record) => (
        <span
          style={{
            color:
              cpa(
                record.totalCost,
                record.totalU65Sales + record.totalACASales
              ) < 100
                ? '#A1E3CB'
                : '#FF000094',
          }}>
          {cpa(
            record.totalCost,
            record.totalU65Sales + record.totalACASales
          ) !== 'No Data'
            ? `$${cpa(
                record.totalCost,
                record.totalU65Sales + record.totalACASales
              )}`
            : 'No Data'}
        </span>
      ),
    },
    {
      title: 'Total Calls',
      dataIndex: 'totalLeads',
      key: 'totalLeads',
      sorter: (a, b) => a.totalLeads - b.totalLeads,
    },
    {
      title: 'Q Calls',
      dataIndex: 'totalBillableLeads',
      key: 'totalBillableLeads',
      sorter: (a, b) => a.totalBillableLeads - b.totalBillableLeads,
    },
    {
      title: 'Marketing Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      sorter: (a, b) => a.totalCost - b.totalCost,
    },
    {
      title: 'Cost Per Call',
      dataIndex: 'CPC',
      key: 'CPC',
      sorter: (a, b) => {
        const cpcA = a.totalCost ? a.totalCost / a.totalBillableLeads : 0;
        const cpcB = b.totalCost ? b.totalCost / b.totalBillableLeads : 0;
        return cpcA - cpcB;
      },
      render: (text, record) => (
        <span>
          {record.totalCost
            ? (record.totalCost / record.totalBillableLeads).toFixed(2)
            : 'No Data'}
        </span>
      ),
    },
    {
      title: 'ACA',
      dataIndex: 'totalACASales',
      key: 'totalACASales',
      sorter: (a, b) => a.totalACASales - b.totalACASales,
    },
    {
      title: 'U65',
      dataIndex: 'totalU65Sales',
      key: 'totalU65Sales',
      sorter: (a, b) => a.totalU65Sales - b.totalU65Sales,
    },
    {
      title: 'Ancillaries',
      dataIndex: 'totalAncillaries',
      key: 'totalAncillaries',
      sorter: (a, b) => a.totalAncillaries - b.totalAncillaries,
    },
    {
      title: 'Premium',
      dataIndex: 'totalPremium',
      key: 'totalPremium',
      sorter: (a, b) => {
        const costA = a.totalPrivateMedPremium + a.totalAncillariesPremium;
        const costB = b.totalPrivateMedPremium + b.totalAncillariesPremium;
        return costA - costB;
      },
      render: (text, record) => {
        const totalPremium =
          record.totalPrivateMedPremium + record.totalAncillariesPremium;
        const maxPremium = 300; // Set this to the max value you expect
        const percentage = Math.min((totalPremium / maxPremium) * 100, 100);

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>${totalPremium.toFixed(2).toLocaleString()}</span>
            <div
              style={{
                marginLeft: 10,
                width: 200,
                height: 20,
                position: 'relative',
                borderRadius: '8px',
                overflow: 'hidden',
              }}>
              <div
                style={{
                  width: `${percentage}%`,
                  height: '100%',
                  backgroundColor: '#A1E3CB',
                  transition: 'width 0.3s ease',
                  borderRadius: '8px',
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'CR',
      dataIndex: 'cr',
      key: 'cr',
      render: (text, record) => (
        <span
          style={{
            color:
              rate(
                record.totalBillableLeads,
                record.totalACASales + record.totalU65Sales
              ) > 60
                ? '#A1E3CB'
                : '#FF000094',
          }}>
          {rate(
            record.totalBillableLeads,
            record.totalACASales + record.totalU65Sales
          )}
          %
        </span>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: '16px' , float:"right"}} >
        <CSVLink
          data={computedData}
          filename="campaign-performance.csv"
          
        >
          <Button icon={<DownloadOutlined />}>Export CSV</Button>
        </CSVLink>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => `${record.queueId}-${record.officeId}`}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setPageSize(size),
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </div>
  );
};

export default CampaignPerformance;
