/** @format */

import React, { useEffect, useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Flex, Tooltip as TooltipAlias } from 'antd';
import formatCurrency from 'utils/formatCurrency';
import ListedLeadLog from 'api/ListedLeadsLogRoutes';
import cpa from 'utils/cpa';
import Info from 'img/Info.svg';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import axios from 'axios';
import { Col, Row, Table } from 'antd';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement
);

const pieOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    tooltip: {
      bodyFont: {
        size: 16,
      },
      titleFont: {
        size: 18,
      },
      padding: 10,
      callbacks: {
        label: function (tooltipItem) {
          return `Total Premium: $${tooltipItem.formattedValue}`;
        },
      },
    },
    title: {
      display: false,
      text: 'Distribution of Categories',
    },
  },
  elements: {
    arc: {
      borderWidth: 2,
      borderAlign: 'inner',
      borderRadius: 5,
      hoverOffset: 15,
    },
  },
  cutout: '50%',
};

const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Average Call Length (s)',
      color: '#000000',
    },
  },
};

const FullPieChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [sortedData, setSortedData] = useState([]);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);

  // Predefined background colors
  const backgroundColors = [
    '#95A4FC',
    '#A1E3CB',
    '#BAEDBD',
    '#B1E3FF',
    '#A8C5DA',
    '#8BC34A',
    '#FFE999',
    '#C6C7F8',
    '#C3A6C6',
    '#FFCB83',
    '#FFC107',
    '#FF5722',
    '#9C27B0',
    '#FF9800',
    '#00BCD4',
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        
        // Fetch both data sources in parallel
        const [performanceRes, costRes] = await Promise.all([
          axios.get(`${apiUrl}/api/performance/overview/global-performance`, {
            params: { startDate, endDate },
            headers: { Authorization: `Bearer ${token}` },
          }),
          ListedLeadLog.getListedLeadCostByDateRange(startDate, endDate)
        ]);
  
        // Add listed cost to Keystone Data (FIXED costRes.data)
        const modifiedData = performanceRes.data.map(item => {
          if (item.globalName === 'Keystone Data') {
            return {
              ...item,
              totalCost: Number(item.totalCost || 0) + Number(costRes.totalCost || 0)
            };
          }
          return item;
        });
  
        const filteredData = modifiedData.filter(
          (item) => item.totalPrivateMedPremium + item.totalAncillariesPremium > 0
        );
  
        if (filteredData.length > 0) {
          const sortedData = filteredData
            .map((item, index) => ({
              ...item,
              color: backgroundColors[index % backgroundColors.length],
              averageCallLength: item.totalBillableLeads
                ? item.totalCallLength / item.totalBillableLeads
                : 0,
            }))
            .sort(
              (a, b) =>
                b.totalPrivateMedPremium +
                b.totalAncillariesPremium -
                (a.totalPrivateMedPremium + a.totalAncillariesPremium)
            )
            .slice(0, 10);
  
          // ADDED MISSING CHART DATA CONSTRUCTION
          const chartData = {
            labels: sortedData.map((item) => item.globalName),
            datasets: [
              {
                data: sortedData.map(
                  (item) =>
                    item.totalPrivateMedPremium + item.totalAncillariesPremium
                ),
                backgroundColor: sortedData.map((item) => item.color),
                borderRadius: 10,
                hoverBackgroundColor: sortedData.map((item) => item.color),
              },
            ],
          };
  
          const barData = {
            labels: sortedData.map((item) => item.globalName),
            datasets: [
              {
                data: sortedData.map((item) => item.averageCallLength),
                backgroundColor: sortedData.map((item) => item.color),
              },
            ],
          };
  
          setChartData(chartData);
          setBarData(barData);
          setSortedData(sortedData);
        } else {
          setChartData({
            labels: [],
            datasets: [{ data: [], backgroundColor: [] }],
          });
          setBarData({
            labels: [],
            datasets: [{ data: [], backgroundColor: [] }],
          });
          setSortedData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
    // ... rest of the socket and return code remains the same
  }, [apiUrl, startDate, endDate]);
  const rate = (total, fraction) => {
    if (total === 0) return 0;
    if (!total || !fraction) return 0;
    const percentage = (fraction / total) * 100;
    return Math.round(percentage);
  };
  const getColorBasedOnPercentage = (percentage) => {
    if (percentage >= 0.75) {
      return 'green'; // Green for 23 and above
    } else if (percentage >= 0.65 && percentage <= 0.74) {
      return '#FFDB58'; // Yellow for 13-22
    } else {
      return 'red'; // Red for 12 and below
    }
  };
  const calculatePtc = (data) => {
    const premium =
      (data.totalAncillariesPremium || 0) + (data.totalPrivateMedPremium || 0);
    const cost = data.totalCost || 0;
    if (cost === 0) return 0;
    return premium / cost;
  };

  const siaScore = (data) => {
    const ptc = calculatePtc(data);
    const score = ptc * (data.totalACASales + data.totalU65Sales);
    return score;
  };
  const formatPtc = (value) => `${value.toFixed(2)}x`;

  const columns = [
    {
      title: (
        <TooltipAlias title='The name of the vendor'>Vendor Name</TooltipAlias>
      ),
      dataIndex: 'globalName',
      key: 'globalName',
      render: (_, record) => (
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: record.color,
              marginRight: '10px',
            }}></span>
          <span>{record.globalName}</span>
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span>CPA-A</span>
          <TooltipAlias title='Cost Per Acquisition without Major Med '>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'CPA-A',
      key: 'CPA-A',
      render: (_, record) => {
        const { formatted } = cpa(record.totalCost, record.totalU65Sales); // Only get formatted value
        return <span>{formatted}</span>; // Render the formatted value (string)
      },
      sorter: (a, b) => {
        const { value: cpaA } = cpa(a.totalCost, a.totalU65Sales); // Get raw number for sorting
        const { value: cpaB } = cpa(b.totalCost, b.totalU65Sales);

        if (cpaA === null || cpaB === null) return 0; // Handle 'No Data' case
        return cpaA - cpaB; // Sort by raw number
      },
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span> CPA-B</span>
          <TooltipAlias title='Cost Per Acquisition with Major Med'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'CPA-B',
      key: 'CPA-B',
      render: (_, record) => {
        const { formatted } = cpa(
          record.totalCost,
          record.totalU65Sales + record.totalACASales
        );
        return <span>{formatted}</span>; // Render formatted string
      },
      sorter: (a, b) => {
        const { value: cpaA } = cpa(
          a.totalCost,
          a.totalU65Sales + a.totalACASales
        ); // Get value for a
        const { value: cpaB } = cpa(
          b.totalCost,
          b.totalU65Sales + b.totalACASales
        ); // Get value for b

        if (cpaA === null || cpaB === null) return 0; // Handle "No Data" case or null values
        return cpaA - cpaB; // Sort based on raw values
      },
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>Sales </span>
          <TooltipAlias title='Total Premium  to Total Marketing cost'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'Sales',
      key: 'Sales',
      render: (_, record) => (
        <span>{record.totalU65Sales + record.totalACASales}</span>
      ),
      sorter: (a, b) =>
        a.totalU65Sales + a.totalACASales - (b.totalU65Sales + b.totalACASales),
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>ACA </span>
          <TooltipAlias title='Total ACA Sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'ACA',
      key: 'ACA',
      render: (_, record) => <span>{record.totalACASales}</span>,
      sorter: (a, b) => a.totalACASales - b.totalACASales,
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>U65</span>
          <TooltipAlias title='Total ACA Sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'ACA',
      key: 'ACA',
      render: (_, record) => <span>{record.totalU65Sales}</span>,
      sorter: (a, b) => a.totalU65Sales - b.totalU65Sales,
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>Ancillary</span>
          <TooltipAlias title='Total ACA Sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'Ancillary',
      key: 'Ancillary',
      render: (_, record) => <span>{record.totalAncillaries}</span>,
      sorter: (a, b) => a.totalAncillaries - b.totalAncillaries,
    },

    {
      title: (
        <TooltipAlias title='The total number of billable leads'>
          Q Call
        </TooltipAlias>
      ),
      dataIndex: 'totalBillableLeads',
      key: 'totalBillableLeads',
      render: (_, record) => <span>{record.totalBillableLeads}</span>,
      sorter: (a, b) => a.totalBillableLeads - b.totalBillableLeads,
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span> CR</span>
          <TooltipAlias title='Close Ratio'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'CR',
      key: 'CR',
      render: (_, record) => {
        const totalSales = record.totalACASales + record.totalU65Sales;
        const totalLeads = record.totalBillableLeads;

        // Calculate Close Ratio directly inside the render function

        return <span>{rate(totalLeads, totalSales)}%</span>;
      },
      sorter: (a, b) => {
        const crA =
          a.totalBillableLeads > 0
            ? Math.round(
                ((a.totalACASales + a.totalU65Sales) / a.totalBillableLeads) *
                  100
              )
            : 0;
        const crB =
          b.totalBillableLeads > 0
            ? Math.round(
                ((b.totalACASales + b.totalU65Sales) / b.totalBillableLeads) *
                  100
              )
            : 0;

        return crA - crB;
      },
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span>P.T.C.</span>
          <TooltipAlias title='Total Premium to Total Marketing cost'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'PTC',
      key: 'PTC',
      render: (_, record) => (
        <span
          style={{
            display: 'inline-block',
            padding: '2px 5px',
            borderRadius: '10px',
            color: '#fff',
            backgroundColor: getColorBasedOnPercentage(calculatePtc(record)),
          }}>
          {formatPtc(calculatePtc(record))}
        </span>
      ),
      sorter: (a, b) => calculatePtc(a) - calculatePtc(b),
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span> AP</span>
          <TooltipAlias title='Average Premium'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'AP',
      key: 'AP',
      render: (_, record) => (
        <span>
          {formatCurrency(record.totalPrivateMedPremium / record.totalU65Sales)}
        </span>
      ),
      sorter: (a, b) =>
        a.totalPrivateMedPremium / a.totalU65Sales -
        b.totalPrivateMedPremium / b.totalU65Sales,
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>Total Premium</span>
        </span>
      ),
      dataIndex: 'Total Premium',
      key: 'Total Premium',
      render: (_, record) => (
        <span>
          {formatCurrency(
            record.totalPrivateMedPremium + record.totalAncillariesPremium
          )}
        </span>
      ),
      sorter: (a, b) =>
        a.totalPrivateMedPremium +
        a.totalAncillariesPremium -
        (b.totalPrivateMedPremium + b.totalAncillariesPremium),
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span>SIA-Score</span>
          <TooltipAlias title='P.T.C times total sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'SIA',
      key: 'SIA',
      render: (_, record) => <span>{siaScore(record).toFixed(2)}</span>,
      sorter: (a, b) => siaScore(a) - siaScore(b),
    },
  ];
  // Function to calculate percentage rate

  return (
    <Row gutter={[16, 30]}>
      <Col sm={12} md={12} lg={16} xl={12}>
        <div className='bar-container'>
          <Bar data={barData} options={barOptions} />
        </div>
      </Col>
      <Col sm={12} md={12} lg={12} xl={12}>
        <div className='fl-pie-container'>
          <div style={{ width: '100%', fontSize: '14px', fontWeight: 600 }}>
            Campaign Rank (By Total Premium)
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}>
            <Pie data={chartData} options={pieOptions} />
          </div>
          <div className='table-container' style={{ width: '100%' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                {sortedData.length > 0 ? (
                  sortedData.map((item, index) => (
                    <tr key={index} style={{ height: '30px' }}>
                      <td>
                        <span
                          style={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: item.color,
                            marginRight: '10px',
                          }}></span>
                        {item.globalName}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        $
                        {(
                          item.totalPrivateMedPremium +
                          item.totalAncillariesPremium
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='2'>No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={sortedData}
          rowKey={(record) => record.globalName}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default FullPieChart;
