/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import backArrow from 'img/ArrowLeft.svg';
import { Col, Row, Select } from 'antd';
import plus from 'img/Plus.svg';
import { setFormDisplay } from 'state/slice/buttonSlice';
import remove from 'img/Minus.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from 'state/slice/dropDownSlice'; // Correct import for setUserI
const SubmitionForm = ({ hideForm }) => {
  const sherpas = [
    'AOBG',
    'Premier',
    'Allstate',
    'Messer',
    'Referal',
    'Uncontracted',
  ];
  const QLEs = [
    'Loss of health coverage',
    'Changes in household',
    'Changes in residence',
    'Other qualifying events',
    'Change of Income',
  ];

  const planTypes = ['Private Med', 'Ancillary', 'Major Medical'];

  const initialState = {
    request: false,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    bestPhoneNumber: '',
    dateOfBirth: '',
    SSN: '',
    email: '',
    typeOfWork: '',
    address: {
      street: '',
      aptUnite: '',
      city: '',
      state: '',
      postalCode: '',
    },
    spouseInfo: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      SSN: '',
    },
    dependentsInfo: [
      {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        SSN: '',
      },
    ],
    householdIncome: null,
    QLE: '',
    planType: [],
    ancillaries: [
      {
        carrier: '',
        isPostDate: null,
        billingDate: '',
        premium: '',
        status: '',
      },
    ],

    privateMed: {
      carrier: '',
      isPostDate: null,
      billingDate: '',
      status: '',
      premium: '',
    },
    majorMed: {
      carrier: '',
      isPostDate: null,
      billingDate: '',
      status: '',
      premium: '',
    },
    memberId: '',
    FFMID: '',
    healthSherpa: '',
    NPN: '',
    AOR: '',
    enrollmentFee: null,
    benefitsURL: '',
    notes: '',
  };
  const generalQuestion = {
    dependents: false,
    status: 'single',
  };

  const [formData, setFormData] = useState(initialState);
  const [aors, setAors] = useState([]);
  const [npns, setNpns] = useState([]);
  const [generalQ, setGeneralQ] = useState(generalQuestion);
  const [users, setUsers] = useState([]); // State to store users
  const dispatch = useDispatch();
  const handleExit = (type) => {
    dispatch(setFormDisplay(type));
  };
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const selectedUserId = useSelector((state) => state.dropDown.userId);
  useEffect(() => {
    const fetchAors = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/aor/aor-list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        setAors(response.data);
      } catch (error) {
        console.error('Failed to fetch AORs:', error);
      }
    };

    fetchAors();
  }, [apiUrl]);

  useEffect(() => {
    const fetchNpns = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/npn/npn-list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        setNpns(response.data);
      } catch (error) {
        console.error('Failed to fetch NPNs:', error);
      }
    };
    fetchNpns();
  }, [apiUrl]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/agents`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    fetchUsers();
  }, [apiUrl]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox' && name === 'planType') {
      // Handles checkbox updates for planType
      setFormData((prevState) => {
        // Check if acaRequest is true and the value is "Major Medical"
        let updatedPlanType = [...prevState.planType];
        if (prevState.request && value === 'Major Medical') {
          // Ensure "Major Medical" is added to the array if acaRequest is true
          if (!updatedPlanType.includes('Major Medical')) {
            updatedPlanType.push('Major Medical');
          }
        } else {
          // Normal checkbox handling for other plan types
          updatedPlanType = checked
            ? [...prevState.planType, value]
            : prevState.planType.filter((type) => type !== value);
        }

        return {
          ...prevState,
          planType: updatedPlanType,
        };
      });
    } else if (type === 'radio' && name === 'request') {
      // Convert string value to boolean for radio input
      const isAcaRequest = value === 'true';
      setFormData((prevState) => ({
        ...prevState,
        [name]: isAcaRequest,
        // Automatically update the planType if acaRequest is true
        planType: isAcaRequest
          ? [...prevState.planType, 'Major Medical']
          : prevState.planType.filter((type) => type !== 'Major Medical'),
      }));
    } else if (name.includes('.')) {
      // Handles nested object updates
      const [section, key] = name.split('.');
      setFormData((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: type === 'checkbox' ? checked : value,
        },
      }));
    } else {
      // Default handling for other input types
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handlegeneralQChange = (event) => {
    const { name, value } = event.target;

    setGeneralQ((prevGeneralQ) => ({
      ...prevGeneralQ,
      [name]: value === 'true' ? true : value === 'false' ? false : value, // Handle booleans and string values
    }));
  };

  const handleUserChange = (value) => {
    // Only dispatch if a valid value is selected
    if (value) {
      dispatch(setUserId({ userId: value }));
      console.log(value);
    } else {
      dispatch(setUserId({ userId: null })); // Or set to null or some other default
    }
  };
  const handleAncillaryChange = (index, e) => {
    const { name, value } = e.target;
    const field = name.split('-')[0]; // Extract the field name (carrier, isPostDate, billingDate, premium)
    const newAncillaries = formData.ancillaries.map((ancillary, i) => {
      if (index === i) {
        return {
          ...ancillary,
          [field]: value === 'true' ? true : value === 'false' ? false : value,
        };
      }
      return ancillary;
    });
    setFormData({ ...formData, ancillaries: newAncillaries });
  };
  useEffect(() => {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    const handleWheel = (event) => {
      event.preventDefault();
    };

    numberInputs.forEach((input) =>
      input.addEventListener('wheel', handleWheel)
    );

    // Cleanup event listeners on component unmount
    return () => {
      numberInputs.forEach((input) =>
        input.removeEventListener('wheel', handleWheel)
      );
    };
  }, []);
  const addAncillary = () => {
    setFormData((prevState) => ({
      ...prevState,
      ancillaries: [
        ...prevState.ancillaries,
        {
          carrier: '',
          isPostDate: null,
          billingDate: '',
          premium: '',
          status: '',
        },
      ],
    }));
  };

  const removeAncillary = (index) => {
    console.log('Removing index:', index); // Debugging line
    setFormData((prevState) => ({
      ...prevState,
      ancillaries: prevState.ancillaries.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      userId: selectedUserId || undefined, // Include userId from Redux or leave undefined
    };
    try {
      await axios.post(
        `${apiUrl}/api/policy-form/submit-form`,
        submissionData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      alert('Form submitted successfully');
      setFormData(initialState);
    } catch (error) {
      console.error('Error submitting form', error);
      alert('Failed to submit form');
    }
  };
  useEffect(() => {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    const handleWheel = (event) => {
      event.preventDefault();
    };

    numberInputs.forEach((input) =>
      input.addEventListener('wheel', handleWheel)
    );

    // Cleanup event listeners on component unmount
    return () => {
      numberInputs.forEach((input) =>
        input.removeEventListener('wheel', handleWheel)
      );
    };
  }, []);
  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const addDependent = () => {
    setFormData((prevState) => ({
      ...prevState,
      dependentsInfo: [
        ...prevState.dependentsInfo,
        {
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          SSN: '',
        },
      ],
    }));
  };

  const removeDependent = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      dependentsInfo: prevState.dependentsInfo.filter((_, i) => i !== index),
    }));
  };
  const handleDependentChange = (index, e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      dependentsInfo: prevState.dependentsInfo.map((dependent, i) =>
        i === index ? { ...dependent, [name]: value } : dependent
      ),
    }));
  };
  const enrollmentFee = [0, 20, 25 ,27.5, 30, 50, 80, 99, 125];
  return (
    <div className='u65-form'>
      <div style={{ marginBottom: '28px' }}>
        {' '}
        <button className='white-back-button' onClick={hideForm}>
          <img src={backArrow} alt='back Arrow' />
          <span>Back</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Row align={'center'}>
          <Col sm={24} md={24} lg={16} xl={16}>
            <Row gutter={[24, 24]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-title'>
                  <span>Sales Submission Form</span>
                </div>
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-heading'>
                  <span>User</span>
                </div>
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-input-wrapper'>
                  <label>
                    Choose an agent you're submitting this form on their behalf(
                    if its you leave it blank)
                  </label>
                  <Select
                    showSearch
                    placeholder='Select a User'
                    optionFilterProp='children'
                    onChange={handleUserChange}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: '100%', marginTop: '10px' }}>
                    {users.map((user) => (
                      <Select.Option
                        key={user._id}
                        value={user._id}
                        label={`${capitalize(user.firstname)} ${capitalize(
                          user.lastname
                        )}`} // Capitalized label
                      >
                        {`${capitalize(user.firstname)} ${capitalize(
                          user.lastname
                        )}`}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-heading'>
                  <span>Member Preliminary Information</span>
                </div>
              </Col>
              {/* ACA Request */}
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='radial-input'>
                  <label>Does this form include an ACA request? *</label>
                  <div className='radial-input-wrapper'>
                    <label>
                      <input
                        type='radio'
                        name='request'
                        value='true'
                        checked={formData.request === true}
                        onChange={handleChange}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type='radio'
                        name='request'
                        value='false'
                        checked={formData.request === false}
                        onChange={handleChange}
                      />
                      No
                    </label>
                  </div>
                </div>
              </Col>
              {/* Dependents */}
              {formData.request && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <div className='radial-input'>
                    <label>Does the member have any depedents?*</label>
                    <div className='radial-input-wrapper'>
                      <label>
                        <input
                          type='radio'
                          name='dependents'
                          value='true'
                          checked={generalQ.dependents === true}
                          onChange={handlegeneralQChange}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='dependents'
                          value='false'
                          checked={generalQ.dependents === false}
                          onChange={handlegeneralQChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </Col>
              )}
              {formData.request && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <div className='radial-input'>
                    <label> Marital Status *</label>
                    <div className='radial-input-wrapper'>
                      <label>
                        <input
                          type='radio'
                          name='status'
                          value='single'
                          checked={generalQ.status === 'single'}
                          onChange={handlegeneralQChange}
                        />
                        Single
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='status'
                          value='married'
                          checked={generalQ.status === 'married'}
                          onChange={handlegeneralQChange}
                        />
                        Married
                      </label>
                    </div>
                  </div>
                </Col>
              )}
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-heading'>
                  <span>Plan</span>
                </div>
              </Col>
              {console.log('planType', formData.planType)}
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='radial-input'>
                  <label>Plan Type *</label>
                  <div className='radial-input-wrapper'>
                    {planTypes.map((type) => (
                      <label key={type}>
                        <input
                          type='checkbox'
                          name='planType'
                          value={type}
                          checked={
                            // If acaRequest is true, automatically check "Major Medical"
                            formData.request && type === 'Major Medical'
                              ? true
                              : formData.planType.includes(type)
                          }
                          onChange={(e) => {
                            // If "Major Medical" and acaRequest is true, prevent manual changes
                            if (formData.request && type === 'Major Medical') {
                              return;
                            }
                            // Handle changes for other types or when acaRequest is false
                            handleChange(e);
                          }}
                        />
                        {type}
                      </label>
                    ))}
                  </div>
                </div>
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-heading'>
                  <span>Member Information</span>
                </div>
              </Col>
              <Col sm={24} md={12} lg={12} xl={12}>
                <div className='form-input-wrapper'>
                  <label style={{ marginBottom: '10px' }}>
                    Member First Name *
                  </label>
                  <input
                    type='text'
                    name='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col sm={24} md={12} lg={12} xl={12}>
                <div className='form-input-wrapper'>
                  <label style={{ marginBottom: '10px' }}>
                    Member Last Name *
                  </label>
                  <input
                    type='text'
                    name='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col sm={24} md={12} lg={12} xl={12}>
                <div className='form-input-wrapper'>
                  <label>Member Phone Number *</label>
                  <input
                    type='number'
                    name='phoneNumber'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              {formData.request && (
                <Col sm={24} md={12} lg={12} xl={12}>
                  <div className='form-input-wrapper'>
                    <label style={{ marginBottom: '10px' }}>Email</label>
                    <input
                      type='text'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              )}
              {formData.request && (
                <Col sm={24} md={12} lg={12} xl={12}>
                  <div className='form-input-wrapper'>
                    <label style={{ marginBottom: '10px' }}>Occupation</label>
                    <input
                      type='text'
                      name='typeOfWork'
                      value={formData.typeOfWork}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              )}{' '}
              {formData.request && (
                <Col sm={24} md={12} lg={12} xl={12}>
                  <div className='form-input-wrapper'>
                    <label style={{ marginBottom: '10px' }}>Member SSN *</label>
                    <input
                      type='text'
                      name='SSN'
                      value={formData.SSN}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              )}
              <Col sm={24} md={12} lg={12} xl={12}>
                <div className='form-input-wrapper'>
                  <label>Member Date of Birth *</label>
                  <input
                    type='date'
                    name='dateOfBirth'
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              {formData.request && (
                <Col sm={24} md={12} lg={12} xl={12}>
                  <div className='form-input-wrapper'>
                    <label>Member Household Income *</label>
                    <input
                      type='number'
                      name='householdIncome'
                      value={formData.householdIncome}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              )}
              {formData.request && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <div className='radial-input'>
                    <label>Qualifying life event *</label>
                    <div className='radial-input-wrapper'>
                      {QLEs.map((QLE) => (
                        <label key={QLE}>
                          <input
                            type='radio'
                            name='QLE'
                            value={QLE}
                            checked={formData.QLE === QLE}
                            onChange={handleChange}
                          />
                          {QLE}
                        </label>
                      ))}
                    </div>
                  </div>
                </Col>
              )}
              {formData.planType.includes('Private Med') ||
              formData.planType.includes('Ancillary') ? (
                <Col sm={24} md={12} lg={12} xl={12}>
                  <div className='form-input-wrapper'>
                    <label style={{ marginBottom: '10px' }}>Member Id *</label>
                    <input
                      name='memberId'
                      value={formData.memberId}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              ) : null}
              {formData.planType.includes('Major Medical') &&
                !formData.request && (
                  <Col sm={24} md={12} lg={12} xl={12}>
                    <div className='form-input-wrapper'>
                      <label style={{ marginBottom: '10px' }}>FFM Id *</label>
                      <input
                        name='FFMID'
                        value={formData.FFMID}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                )}{' '}
              {formData.request && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <Row gutter={[24, 24]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-heading'>
                        <span> Address *</span>
                      </div>
                    </Col>

                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label style={{ marginBottom: '10px' }}>
                          Street Address *
                        </label>
                        <input
                          type='text'
                          name='address.street'
                          value={formData.address.street}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label style={{ marginBottom: '10px' }}>Apt/Unit</label>
                        <input
                          type='text'
                          name='address.aptUnite'
                          value={formData.address.aptUnite}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>City *</label>
                        <input
                          type='text'
                          name='address.city'
                          value={formData.address.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>State *</label>
                        <input
                          type='text'
                          name='address.state'
                          value={formData.address.state}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>Zip Code *</label>
                        <input
                          type='text'
                          name='address.postalCode'
                          value={formData.address.postalCode}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
              {generalQ.status === 'married' && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <Row gutter={[24, 24]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-heading'>
                        <span> Spouse Information</span>
                      </div>
                    </Col>

                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label style={{ marginBottom: '10px' }}>
                          Spouse First Name *
                        </label>
                        <input
                          type='text'
                          name='spouseInfo.firstName'
                          value={formData.spouseInfo.firstName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label style={{ marginBottom: '10px' }}>
                          Spouse Last Name *
                        </label>
                        <input
                          type='text'
                          name='spouseInfo.lastName'
                          value={formData.spouseInfo.lastName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>Member Date of Birth *</label>
                        <input
                          type='date'
                          name='spouseInfo.dateOfBirth'
                          value={formData.spouseInfo.dateOfBirth}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label> Spouse SSN *</label>
                        <input
                          type='test'
                          name='spouseInfo.SSN'
                          value={formData.spouseInfo.SSN}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
              {generalQ.dependents && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <Row gutter={[24, 24]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div
                        className='form-heading'
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                        }}>
                        <span>Dependent Information</span>
                        <button
                          className='add-ancillary-button'
                          type='button'
                          onClick={addDependent}>
                          <span>Add</span>
                          <img src={plus} alt='plus icon' />
                        </button>
                      </div>
                    </Col>
                    {formData.dependentsInfo.map((dependent, index) => (
                      <Col key={index} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={[24, 24]}>
                          <Col sm={24} xl={24}>
                            <div className='remove-ancillary-container'>
                              <span>Dependent {index + 1}</span>
                              <button
                                className='remove-ancillary-button'
                                type='button'
                                onClick={() => removeDependent(index)}>
                                <span>Remove</span>
                                <span>
                                  <img src={remove} alt='remove button' />
                                </span>
                              </button>
                            </div>
                          </Col>

                          <Col sm={24} md={12} lg={12} xl={12}>
                            <div className='form-input-wrapper'>
                              <label>First Name *</label>
                              <input
                                type='text'
                                name='firstName'
                                value={dependent.firstName}
                                onChange={(e) =>
                                  handleDependentChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col sm={24} md={12} lg={12} xl={12}>
                            <div className='form-input-wrapper'>
                              <label>Last Name *</label>
                              <input
                                type='text'
                                name='lastName'
                                value={dependent.lastName}
                                onChange={(e) =>
                                  handleDependentChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col sm={24} md={12} lg={12} xl={12}>
                            <div className='form-input-wrapper'>
                              <label>Date of Birth *</label>
                              <input
                                type='date'
                                name='dateOfBirth'
                                value={dependent.dateOfBirth}
                                onChange={(e) =>
                                  handleDependentChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col sm={24} md={12} lg={12} xl={12}>
                            <div className='form-input-wrapper'>
                              <label>SSN *</label>
                              <input
                                type='text'
                                name='SSN'
                                value={dependent.SSN}
                                onChange={(e) =>
                                  handleDependentChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              )}
              {formData.planType.includes('Private Med') && (
                <Col sm={24} md={24} lg={24} xl={24}>
                  <Row gutter={[24, 24]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-heading'>
                        <span> Private Plan Information</span>
                      </div>
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-input-wrapper'>
                        <label>Carrier*</label>
                        <input
                          type='text'
                          id='carrier-input'
                          name='privateMed.carrier'
                          value={formData.privateMed.carrier}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-input-wrapper'>
                        <span>Was the customer charged today? *</span>
                        <div className='radial-input-wrapper'>
                          <label>
                            <input
                              type='radio'
                              name='privateMed.isPostDate'
                              value={false}
                              checked={formData.privateMed.isPostDate === false}
                              onChange={() =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  privateMed: {
                                    ...prevState.privateMed,
                                    isPostDate: false,
                                  },
                                }))
                              }
                            />
                            Yes
                          </label>
                          <label>
                            <input
                              type='radio'
                              name='privateMed.isPostDate'
                              value={true}
                              checked={formData.privateMed.isPostDate === true}
                              onChange={() =>
                                setFormData((prevState) => ({
                                  ...prevState,
                                  privateMed: {
                                    ...prevState.privateMed,
                                    isPostDate: true,
                                  },
                                }))
                              }
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </Col>
                    <Col sm={24} md={24} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>
                          {formData.privateMed.isPostDate
                            ? 'Enter Billing Date*'
                            : "Enter Today's Date*"}
                        </label>
                        <input
                          style={{ width: '100%' }}
                          type='date'
                          name='privateMed.billingDate'
                          value={formData.privateMed.billingDate}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>

                    <Col sm={24} md={24} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>Total Premium</label>
                        <input
                          style={{ width: '100%' }}
                          type='number'
                          name='privateMed.premium'
                          value={formData.privateMed.premium}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>{' '}
                </Col>
              )}
              <Col sm={24} md={24} lg={24} xl={24}>
                {formData.planType.includes('Major Medical') && (
                  <Row gutter={[24, 24]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-heading'>
                        <span> Major Medical Plan Information</span>
                      </div>
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className='form-input-wrapper'>
                        <label>Carrier*</label>
                        <input
                          type='text'
                          id='carrier-input'
                          name='majorMed.carrier'
                          value={formData.majorMed.carrier}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    {formData.request === false && (
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <div className='form-input-wrapper'>
                          <span>Was the customer charged today? *</span>
                          <div className='radial-input-wrapper'>
                            <label>
                              <input
                                type='radio'
                                name='majorMed.isPostDate'
                                value={false}
                                checked={formData.majorMed.isPostDate === false}
                                onChange={() =>
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    majorMed: {
                                      ...prevState.majorMed,
                                      isPostDate: false,
                                    },
                                  }))
                                }
                              />
                              Yes
                            </label>
                            <label>
                              <input
                                type='radio'
                                name='majorMed.isPostDate'
                                value={true}
                                checked={formData.majorMed.isPostDate === true}
                                onChange={() =>
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    majorMed: {
                                      ...prevState.majorMed,
                                      isPostDate: true,
                                    },
                                  }))
                                }
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col sm={24} md={24} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>
                          {formData.request
                            ? 'Enter Requested Billing Date*'
                            : formData.majorMed.isPostDate
                            ? 'Enter Billing Date*'
                            : "Enter Today's Date*"}
                        </label>
                        <input
                          style={{ width: '100%' }}
                          type='date'
                          name='majorMed.billingDate'
                          value={formData.majorMed.billingDate}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>

                    <Col sm={24} md={24} lg={12} xl={12}>
                      <div className='form-input-wrapper'>
                        <label>Total Premium</label>
                        <input
                          style={{ width: '100%' }}
                          type='number'
                          name='majorMed.premium'
                          value={formData.majorMed.premium}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                {formData.planType.includes('Ancillary') && (
                  <Row gutter={[24, 24]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      {' '}
                      <div
                        className='form-heading'
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                        }}>
                        <span> Ancillary Information</span>
                        <button
                          className='add-ancillary-button'
                          type='button'
                          onClick={addAncillary}>
                          <span>Add</span>
                          <img src={plus} alt='plus icon' />
                        </button>
                      </div>
                    </Col>
                    {formData.ancillaries.map((ancillary, index) => (
                      <Col key={index} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={[24, 24]}>
                          <Col sm={24} xl={24}>
                            <div className='remove-ancillary-container'>
                              <span>Ancillary {index + 1}</span>
                              <button
                                className='remove-ancillary-button'
                                type='button'
                                onClick={() => removeAncillary(index)}>
                                <span>Remove</span>
                                <span>
                                  <img src={remove} alt='remove button' />
                                </span>
                              </button>
                            </div>
                          </Col>
                          <Col sm={24} md={24} lg={24} xl={24}>
                            <div className='form-input-wrapper'>
                              <label>Carrier *</label>
                              <input
                                type='text'
                                name={`carrier-${index}`}
                                value={ancillary.carrier}
                                onChange={(e) =>
                                  handleAncillaryChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            style={{ width: '100%' }}>
                            <div className='form-input-wrapper'>
                              <span>Was the customer charged today? *</span>
                              <div className='radial-input-wrapper'>
                                <label>
                                  <input
                                    type='radio'
                                    name={`isPostDate-${index}`}
                                    value='false'
                                    checked={ancillary.isPostDate === false}
                                    onChange={(e) =>
                                      handleAncillaryChange(index, e)
                                    }
                                  />
                                  Yes
                                </label>
                                <label>
                                  <input
                                    type='radio'
                                    name={`isPostDate-${index}`}
                                    value='true'
                                    checked={ancillary.isPostDate === true}
                                    onChange={(e) =>
                                      handleAncillaryChange(index, e)
                                    }
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className='form-input-wrapper'>
                              <label>
                                {ancillary.isPostDate
                                  ? 'Enter Billing Date*'
                                  : "Enter Today's Date*"}
                              </label>
                              <input
                                style={{ width: '100%' }}
                                type='date'
                                name={`billingDate-${index}`}
                                value={ancillary.billingDate}
                                onChange={(e) =>
                                  handleAncillaryChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className='form-input-wrapper'>
                              <label>Premium *</label>
                              <input
                                style={{ width: '100%' }}
                                type='number'
                                name={`premium-${index}`}
                                value={ancillary.premium}
                                onChange={(e) =>
                                  handleAncillaryChange(index, e)
                                }
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                )}
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='form-heading'>
                  <span>Back Office Information</span>
                </div>
              </Col>
              {formData.planType.some((type) =>
                ['Private Med', 'Ancillary'].includes(type)
              ) ? (
                <Col sm={24} md={24} lg={12} xl={12}>
                  <div className='radial-input'>
                    <span>AOR:</span>
                    <Select
                      showSearch
                      placeholder='Select an AOR'
                      optionFilterProp='children'
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'AOR', value },
                        })
                      }
                      value={formData.AOR}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: '100%' }}>
                      {aors.map((aor) => (
                        <Select.Option
                          key={aor._id}
                          value={aor.firstName}
                          label={aor.firstName}>
                          {aor.firstName}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              ) : null}
              {formData.planType.some((type) =>
                ['Major Medical'].includes(type)
              ) ? (
                <Col sm={24} md={24} lg={12} xl={12}>
                  <div className='radial-input'>
                    <span>NPN Override*</span>
                    <Select
                      showSearch
                      placeholder='Select an NPN'
                      optionFilterProp='children'
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'NPN', value },
                        })
                      }
                      value={formData.NPN}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: '100%' }}>
                      {npns.map((npn) => (
                        <Select.Option
                          key={npn._id}
                          value={npn.firstName}
                          label={npn.firstName}>
                          {npn.firstName}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              ) : null}
              {(formData.planType.includes('Major Medical') &&
                formData.planType.includes('Ancillary')) ||
              formData.planType.includes('Major Medical') ||
              formData.request ? (
                <Col sm={24} md={24} lg={12} xl={12}>
                  <div className='radial-input'>
                    <label>Health Sherpa *</label>
                    <Select
                      showSearch
                      placeholder='Select Health Sherpa'
                      optionFilterProp='children'
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'healthSherpa', value },
                        })
                      }
                      value={formData.healthSherpa}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: '100%' }}>
                      {sherpas.map((sherpa) => (
                        <Select.Option
                          key={sherpa}
                          value={sherpa}
                          label={sherpa}>
                          {sherpa}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              ) : null}
              {formData.planType.includes('Major Medical') ||
              formData.request ? (
                <Col sm={24} md={24} lg={12} xl={12}>
                  <div className='input-group'>
                    <label> Benefit URL</label>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      name='benefitsURL'
                      value={formData.benefitsURL}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              ) : null}
              {formData.planType.some((type) =>
                ['Major Med'].includes(type)
              ) ? null : (
                <Col sm={24} md={24} lg={12} xl={12}>
                  {
                    <div className='radial-input'>
                      <label>Enrollment Fee *</label>
                      <Select
                        showSearch
                        placeholder='Select enrollement Fee'
                        optionFilterProp='children'
                        required
                        onChange={(value) =>
                          handleChange({
                            target: { name: 'enrollmentFee', value },
                          })
                        }
                        value={formData.enrollmentFee}
                        style={{ width: '100%' }}>
                        {enrollmentFee.map((fee) => (
                          <Select.Option key={fee} value={fee} label={fee}>
                            ${fee}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  }
                </Col>
              )}
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='input-group'>
                  <label>Notes:</label>
                  <textarea
                    style={{ width: '100%', height: '300px' }}
                    name='notes'
                    value={formData.notes}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className='submit-submission-form'>
                  <button type='submit'>Submit Form</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>{' '}
      </form>
    </div>
  );
};
export default SubmitionForm;
