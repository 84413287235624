/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Downtrend from 'img/ArrowFall.svg';
import Uptrend from 'img/ArrowRise.svg';
import Info from 'img/Info.svg';
import { Col, Row, Checkbox, Flex } from 'antd';
import MonthlyLeadsAndSalesChart from 'components/widgets/monthlyLeadsAndSalesBar';
import TopCampaignTable from 'components/widgets/topcampaign';
import DashboardSkeleton from 'components/admin/skeleton/dashboardSkeleton';
import { Tooltip } from 'antd';
import PremiumsChart from 'components/super-admin/widgets/lineChart';
const SalesSummary = () => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [includePremium, setIncludePremium] = useState(false);
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const officeId = useSelector((state) => state.dropDown.officeId);
  // Check if userData and userData.division are defined and is an array

  useEffect(() => {
    if (!officeId) {
      return;
    }
    const fetchSummary = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        const response = await axios.get(
          `${apiUrl}/api/stats/super-admin/office/sales-summary/${officeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: startDate,
              endDate: endDate,
            },
          }
        );
        console.log('API Response:', response.data);
        setSummary(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching summary data:', err);
        setError(
          err.response ? err.response.data.error : 'Error fetching summary data'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [startDate, endDate, apiUrl, officeId]);

  if (loading) {
    return <DashboardSkeleton />;
  }

  if (error) {
    console.log('Error State:', error);
    return <div>Error: {error}</div>;
  }

  // Function to calculate percentage rate
  const rate = (total, fraction) => {
    if (total === 0) return 0;
    if (!total || !fraction) return 0;
    const percentage = (fraction / total) * 100;
    return Math.round(percentage);
  };

  // Function to format amount as local string
  const localString = (amount) => {
    return amount ? amount.toLocaleString() : '0';
  };
  const acaPremium = summary.totalACASales * 50;
  const totalPremium = includePremium
    ? summary.totalPremium + acaPremium
    : summary.totalPremium;

  // Function to calculate CPA
  const cpa = (totalCost, totalSales) => {
    if (!totalCost || !totalSales) return 0;
    const average = totalCost / totalSales;
    return Math.round(average);
  };

  // Function to calculate percentage rate trend
  const rateTrend = (previous, current) => {
    if (previous === 0) return current === 0 ? 0 : 100;
    if (!previous || !current) return 0;
    return ((current - previous) / previous) * 100;
  };

  const totalSpentTrend = rateTrend(summary.previousCost, summary.totalCost);

  const cpaTrend = rateTrend(
    cpa(summary.previousCost, summary.previousU65Sales),
    cpa(summary.totalCost, summary.totalU65Sales)
  );
  const leadsTrend = rateTrend(
    summary.previousBillableLeads,
    summary.totalBillableLeads
  );
  const totalSalesTrend = rateTrend(
    summary.previousACASales + summary.previousU65Sales,
    summary.totalU65Sales + summary.totalACASales
  );
  const maJorMedTrend = rateTrend(
    summary.previousACASales,
    summary.totalACASales
  );
  const privateMedTrend = rateTrend(
    summary.previousU65Sales,
    summary.totalU65Sales
  );
  const ancillariesTrend = rateTrend(
    summary.previousAncillaries,
    summary.totalAncillaries
  );
  const totalLeadsTrend = rateTrend(
    summary.previousLeads,
    summary.totalLeads
  );
  return (
    <div className='dashboard-container'>
      <Row gutter={[28, 28]} className='rates'>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-blue'>
            <div className='stats-title'>
              <span> Total Marketing Cost </span>
              <Tooltip title='Total marketing cost '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>${localString(summary.totalCost)}</h2>
              <div>
                <span>
                  {totalSpentTrend.toFixed(2)}%
                  <img
                    src={totalSpentTrend < 0 ? Downtrend : Uptrend}
                    alt={totalSpentTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span> P.T.C</span>
              <Tooltip title='Total Premium to Total Marketing cost'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>
                {(() => {
                  const premium = parseFloat(totalPremium);
                  const cost = parseFloat(summary.totalCost);
                  if (cost === 0) return '0.00x';
                  return (premium / cost || 0).toFixed(2) + 'x';
                })()}
              </h2>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>CPA-A</span>
              <Tooltip title='Cost Per Acquisition without Major Med '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>
                {' '}
                ${localString(cpa(summary.totalCost, summary.totalU65Sales))}
              </h2>
              <div>
                <span>
                  {cpaTrend.toFixed(2)}%
                  <img
                    src={cpaTrend < 0 ? Downtrend : Uptrend}
                    alt={cpaTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
       <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-purple' style={{ height: '100%' }}>
            <div className='stats-title'style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width:'100%'}}>
              <span>Q Calls   </span>
                <div>
                  <Flex justify='space-between' gap={5}>
                <span> Total calls </span>
              <Tooltip title='Total billable Queue calls'>
                <img src={Info} alt='info icon' />
              </Tooltip></Flex></div>
               
            </div>
            <div className='stats' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <h2>{summary.totalBillableLeads}</h2>
              <h2>{summary.totalLeads}</h2>
              
            </div>
          </div>
        </Col>
         
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>CPA-B</span>
              <Tooltip title='Cost Per Acquisition with Major Med '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>
                {' '}
                $
                {localString(
                  cpa(
                    summary.totalCost,
                    summary.totalU65Sales + summary.totalACASales
                  )
                )}
              </h2>
              <div>
                <span>
                  {cpaTrend.toFixed(2)}%
                  <img
                    src={cpaTrend < 0 ? Downtrend : Uptrend}
                    alt={cpaTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div
              className='stats-title'
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  flex: 'row',
                  gap: '4px',
                  alignItems: 'center',
                }}>
                <span>Total Premium</span>
                <Tooltip title='Total premium click the check mark to include ACA sales'>
                  <img src={Info} alt='info icon' style={{ width: '16px' }} />
                </Tooltip>
              </div>
              {/* Checkbox inside stats-title */}
              <Checkbox
                checked={includePremium}
                onChange={(e) => setIncludePremium(e.target.checked)}
                style={{ marginLeft: '10px' }}></Checkbox>
            </div>

            <div className='stats'>
              <h2>${totalPremium.toLocaleString()}</h2>
              <div>
                <span>
                  {maJorMedTrend.toFixed(2)}%
                  <img
                    src={maJorMedTrend < 0 ? Downtrend : Uptrend}
                    alt={maJorMedTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>ACA Core</span>
              <Tooltip title='Total Major Medical sold '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2> {summary.totalACASales}</h2>
              <div>
                <span>
                  {maJorMedTrend.toFixed(2)}%
                  <img
                    src={maJorMedTrend < 0 ? Downtrend : Uptrend}
                    alt={maJorMedTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div
            className='rates-purple'
            style={{ backgroundColor: '#baedbd', height: '100%' }}>
            <div className='stats-title'>
              <span>Close Ratio</span>
              <Tooltip title='Close Ratio '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='states'>
              <h2>
                {' '}
                {rate(
                  summary.totalBillableLeads,
                  summary.totalACASales + summary.totalU65Sales
                )}
                %
              </h2>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>U65 Core</span>
              <Tooltip title='Total Private Med sold '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2> {summary.totalU65Sales}</h2>
              <div>
                <span>
                  {privateMedTrend.toFixed(2)}%
                  <img
                    src={privateMedTrend < 0 ? Downtrend : Uptrend}
                    alt={privateMedTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span> Ancillaries</span>
              <Tooltip title=' Total Ancillary Sold '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2> {summary.totalAncillaries}</h2>
              <div>
                <span>
                  {ancillariesTrend.toFixed(2)}%
                  <img
                    src={ancillariesTrend < 0 ? Downtrend : Uptrend}
                    alt={ancillariesTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>{' '}
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className='rates-light'>
            <div className='stats-title'>
              <span>Total Post Dates</span>
              <Tooltip title='Total Post Date sales'>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>{localString(summary.totalPost)}</h2>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
          <div
            className='rates-purple'
            style={{ backgroundColor: '#ffe999', height: '100%' }}>
            <div className='stats-title'>
              <span>Sales</span>
              <Tooltip title=' Total sales '>
                <img src={Info} alt='info icon' />
              </Tooltip>
            </div>
            <div className='stats'>
              <h2>{summary.totalACASales + summary.totalU65Sales}</h2>
              <div>
                <span>
                  {totalSalesTrend.toFixed(2)}%
                  <img
                    src={totalSalesTrend < 0 ? Downtrend : Uptrend}
                    alt={totalSalesTrend < 0 ? 'Downtrend' : 'Uptrend'}
                  />
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <MonthlyLeadsAndSalesChart
        endpoint={`${apiUrl}/api/stats/super-admin/office/monthly-summary/${officeId}`}
      />
      <PremiumsChart
        endpoint={`api/stats/super-admin/office/weekly-premium/${officeId}`}
      />
      {/** <TopCampaignTable endpoint={`${apiUrl}/api/top-campaigns`} />*/}
    </div>
  );
};

export default SalesSummary;
